import React from 'react'
import CheckBoxInput from '../components/inputs/CheckBoxInput'
import ColorPickerInput from '../components/inputs/ColorPickerInput'
import DateTimeInput from '../components/inputs/DateTimeInput'
import MultiSelectInput from '../components/inputs/MultiSelectInput'
import PasswordInput, { PasswordInputMode } from '../components/inputs/PasswordInput'
import SearchableSelectInput from '../components/inputs/SearchableSelectInput'
import SelectInput from '../components/inputs/SelectInput'
import TextInput from '../components/inputs/TextInput'
import TimespanInput from '../components/inputs/TimespanInput'
import { IMultiSelectItem } from '../entity/multiselect'
import { PlatformType } from '../entity/platforms'
import { GatewayEntity, GatewayPlatformEntity } from '../entity/system-settings'
import { isSwitchUnsupportedPoolType } from './aggregation-pool'
import SearchableSelectCreateInput from '../components/inputs/SearchableSelectCreateInput'
import CreateInput from '../components/inputs/CreateInput'

export function textInput(name: string, isDisabled?: boolean, placeholder?: string, className?: string, tooltip?: string, tooltipFlag?: boolean) {
  return {
    name,
    tooltip,
    tooltipFlag,
    placeholder,
    type: 'text',
    isDisabled,
    errMsg: '',
    className,
    setState: null,

    stateSetup(setState: any) {
      this.setState = setState
      return this
    },
    skipWhen(predicat: any) {
      let condition = false
      if (typeof predicat === 'function') {
        condition = predicat()
      } else {
        condition = predicat
      }
      if (condition) {
        this.type = 'skip'
      }
      return this
    },
    disabled(predicat: boolean) {
      this.isDisabled = predicat
      return this
    },
    errorMessage(msg: string) {
      this.errMsg = msg
      return this
    },
    holder(str: string) {
      this.placeholder = str
      return this
    },
  }
}

export function passwordInput(name: string, mode?: PasswordInputMode, placeholder?: string) {
  return {
    name,
    placeholder,
    type: 'password',
    mode,
    _disabled: false,

    setMode(type: string) {
      this.mode = type === 'add' ? 'setup' : 'change'
      return this
    },

    disabled(predicat: boolean) {
      this._disabled = predicat
      return this
    },
    holder(str: string) {
      this.placeholder = str
      return this
    },
    skipWhen(predicat: any) {
      let condition = false
      if (typeof predicat === 'function') {
        condition = predicat()
      } else {
        condition = predicat
      }
      if (condition) {
        this.type = 'skip'
      }
      return this
    },
  }
}

export function timeInput(name: string, zIndex?: any, showDateNotTime?: boolean, maxDate?: Date) {
  return {
    name,
    type: 'time',
    setState: null,
    zIndex,
    showDateNotTime,
    maxDate,
    stateSetup(setState: any) {
      this.setState = setState
      return this
    },
    skipWhen(predicat: boolean) {
      if (predicat) {
        this.type = 'skip'
      }
      return this
    },
  }
}

export function timespanInput(name: string, zIndex?: any) {
  return {
    name,
    type: 'timespan',
    setState: null,
    zIndex,
    skipWhen(predicat: boolean) {
      if (predicat) {
        this.type = 'skip'
      }
      return this
    },
    stateSetup(setState: any) {
      this.setState = setState
      return this
    },
  }
}

export function selectInput(name: string, options?: string[], isDisabled?: boolean, state?: any, setState?: any, convert?: any, filterDisabled?: any) {
  return {
    errMsgText: '',
    name,
    type: 'select',
    options,
    errMsg: '',
    isDisabled,
    state,
    setState,
    convert,
    filterDisabled,
    optionItems(opts: any[]) {
      this.options = opts
      return this
    },
    converter(converterFunc: any) {
      this.convert = converterFunc
      return this
    },
    disabled(predicat: boolean) {
      this.isDisabled = predicat
      return this
    },
    filter(predicat: any) {
      this.filterDisabled = predicat
      return this
    },
    skipWhen(predicat: boolean) {
      if (predicat) {
        this.type = 'skip'
      }
      return this
    },
    errorMessage(msg: string) {
      this.errMsg = msg
      return this
    },
    textMessages(msg: string) {
      this.errMsgText = msg
      return this
    },
  }
}

export function mselectInput(
  name: string,
  options: IMultiSelectItem[],
  width?: any,
  isCreatable?: boolean,
  CreateTitle?: string,
  tooltip?: string,
  tooltipFlag?: boolean,
  isDisabled?: boolean,
) {
  return {
    name,
    type: 'multiselect',
    options,
    width,
    setState: null,
    _style: null,
    tooltip,
    tooltipFlag,
    isCreatable,
    CreateTitle,
    isDisabled,
    zIndex: false,
    zIndexNumber: 1,
    optionZindex(opts: boolean, number?: any) {
      this.zIndex = opts
      this.zIndexNumber = number
      return this
    },
    stateSetup(setState: any) {
      this.setState = setState
      return this
    },
    styles(styleObject: any) {
      this._style = styleObject
      return this
    },
    skipWhen(predicat: boolean) {
      if (predicat) {
        this.type = 'skip'
      }
      return this
    },
    disabled(predicat?: any) {
      this.isDisabled = predicat
      return this
    },
  }
}

export function sselectInput(
  name: string,
  options?: any[],
  isSearchable: boolean = true,
  isDisabled?: boolean,
  state?: any,
  setState?: any,
  tooltipFlag?: any,
  tooltip?: any,
  convert?: any,
  filterDisabled?: any,
) {
  return {
    errMsgText: '',
    name,
    type: 'searchable_select',
    options,
    errMsg: '',
    isDisabled,
    state,
    setState,
    convert,
    isSearchable,
    filterDisabled,
    tooltip,
    tooltipFlag,
    zIndex: true,
    zIndexNumber: 32323232323,
    optionZindex(opts: boolean, number?: any) {
      this.zIndex = opts
      this.zIndexNumber = number
      return this
    },
    optionItems(opts: any[]) {
      this.options = opts
      return this
    },

    converter(converterFunc: any) {
      this.convert = converterFunc
      return this
    },
    disabled(predicat: boolean) {
      this.isDisabled = predicat
      return this
    },
    filter(predicat: any) {
      this.filterDisabled = predicat
      return this
    },
    skipWhen(predicat: boolean) {
      if (predicat) {
        this.type = 'skip'
      }
      return this
    },
    errorMessage(msg: string) {
      this.errMsg = msg
      return this
    },
    textMessages(msg: string) {
      this.errMsgText = msg
      return this
    },
  }
}

export function sselectCreateInput(name: string, options: IMultiSelectItem[]) {
  return {
    name,
    type: 'searchable_select_create',
    options,
    isDisabled: false,
    disabled(predicat: boolean) {
      this.isDisabled = predicat
      return this
    },
  }
}

export function createInput(name: string) {
  return {
    name,
    type: 'createInput',
    _className: '',
    _textWarning: false,
    className(classes: string) {
      this._className = classes
      return this
    },
    textWarning(text: boolean) {
      this._textWarning = text
      return this
    },
  }
}

export function cmselectInput(name: string, options: IMultiSelectItem[], placeholder?: string, tooltip?: string, tooltipFlag?: boolean, createTitle?: string) {
  return {
    name,
    type: 'createableMultiselect',
    createTitle,
    options,
    tooltip,
    tooltipFlag,
    placeholder,
    _className: '',
    _onCreateOption: undefined,
    _textWarning: false,
    skipWhen(predicat: any) {
      let condition = false
      if (typeof predicat === 'function') {
        condition = predicat()
      } else {
        condition = predicat
      }
      if (condition) {
        this.type = 'skip'
      }
      return this
    },
    className(classes: string) {
      this._className = classes
      return this
    },
    textWarning(text: boolean) {
      this._textWarning = text
      return this
    },
    onCreateOption(cb: any) {
      this._onCreateOption = cb
      return this
    },
    holder(str: string) {
      this.placeholder = str
      return this
    },
    title(str: string) {
      this.createTitle = str
      return this
    },
  }
}

export function checkboxInput(name: string, flag?: boolean) {
  return {
    name,
    type: 'checkbox',
    flag,
    _state: null,
    _setState: null,

    disabled(isDisabled: boolean) {
      if (isDisabled) {
        this.type = 'checkbox-disabled'
      }
      return this
    },
    skipWhen(predicat: any) {
      let condition = false
      if (typeof predicat === 'function') {
        condition = predicat()
      } else {
        condition = predicat
      }
      if (condition) {
        this.type = 'skip'
      }
      return this
    },

    state(stateObject: any) {
      this._state = stateObject
      return this
    },

    setState(setStateCallback: any) {
      this._setState = setStateCallback
      return this
    },
  }
}

export function checkboxDisabledInput(name: string) {
  return {
    name,
    type: 'checkbox-disabled',
  }
}

export function blankInput() {
  return {
    type: 'blank',

    skipWhen(predicat: any) {
      let condition = false
      if (typeof predicat === 'function') {
        condition = predicat()
      } else {
        condition = predicat
      }
      if (condition) {
        this.type = 'skip'
      }
      return this
    },
  }
}

export function colorsInput(name: string) {
  return {
    type: 'colors',
    name,
    isDisabled: false,
    _style: null,

    disabled(isDisabled: boolean) {
      this.isDisabled = isDisabled
      return this
    },

    styles(styleObject: any) {
      this._style = styleObject
      return this
    },
  }
}

export function buildControlsExtTwoPerLine(controlDescriptors: any[], state: any, setState: any, translatePrefix: string, touched?: any, setTouched?: any, errors?: any) {
  return (
    <div className="row">
      {buildControlsExt(
        controlDescriptors.filter((descr: any) => descr),
        state,
        setState,
        translatePrefix,
        true,
        touched,
        setTouched,
        errors,
      )}
    </div>
  )
}

export function buildControlsExt(
  controlDescriptors: any[],
  state: any,
  setState: any,
  translatePrefix: string,
  twoPerLine?: boolean,
  touched?: any,
  setTouched?: any,
  errors?: any,
) {
  // eslint-disable-next-line array-callback-return
  return controlDescriptors.map((control: any, index: number) => {
    switch (control.type) {
      case 'blank':
        return <div key={index} className={twoPerLine ? 'col-12 ' : 'col-12'} />
      case 'text':
        return (
          <TextInput
            state={state}
            setState={control.setState || setState}
            touched={touched}
            setTouched={setTouched}
            errors={errors}
            name={control.name}
            placeholder={control.placeholder}
            label={`${translatePrefix}.${control.name}`}
            key={control.name}
            isDisabled={control.isDisabled}
            className={control.className ? 'col-12' : twoPerLine ? 'col-6' : ''}
            errorText={control.errMsg}
            tooltipFlag={control.tooltipFlag}
            tooltip={control.tooltip}
          />
        )
      case 'select':
        return (
          <SelectInput
            state={control.state ? control.state : state}
            setState={control.setState ? control.setState : setState}
            options={control.options}
            name={control.name}
            errors={errors}
            setTouched={setTouched}
            label={`${translatePrefix}.${control.name}`}
            key={control.name}
            className={control.errMsgText ? 'col-12' : 'col-6'}
            isDisabled={control.isDisabled}
            convert={control.convert}
            filterDisabled={control.filterDisabled}
            errorText={control.errMsg}
            touched={touched}
            errMsgText={control.errMsgText}
          />
        )
      case 'searchable_select':
        return (
          <SearchableSelectInput
            state={control.state ? control.state : state}
            setState={control.setState ? control.setState : setState}
            options={control.options}
            name={control.name}
            touched={touched}
            setTouched={setTouched}
            errors={errors}
            label={`${translatePrefix}.${control.name}`}
            key={control.name}
            className={control.errMsgText ? 'col-12' : 'col-6'}
            isDisabled={control.isDisabled}
            isSearchable={control.isSearchable}
            errMsgText={control.errMsgText}
            errorText={control.errMsg}
            zIndex={control.zIndex}
            zIndexNumber={control.zIndexNumber}
            tooltipFlag={control.tooltipFlag}
            tooltip={control.tooltip}
          />
        )
      case 'searchable_select_create':
        return (
          <SearchableSelectCreateInput
            state={control.state ? control.state : state}
            setState={control.setState ? control.setState : setState}
            options={control.options}
            name={control.name}
            touched={touched}
            setTouched={setTouched}
            errors={errors}
            label={`${translatePrefix}.${control.name}`}
            key={control.name}
            className={twoPerLine ? 'col-6' : ''}
            isDisabled={control.isDisabled}
          />
        )
      case 'time':
        return (
          <DateTimeInput
            zIndex={control.zIndex}
            state={state}
            setState={control.setState ?? setState}
            name={control.name}
            label={`${translatePrefix}.${control.name}`}
            key={control.name}
            className={twoPerLine ? 'col-6' : ''}
            maxDate={control.maxDate}
            showDateNotTime={control.showDateNotTime}
          />
        )
      case 'timespan':
        return (
          <TimespanInput
            zIndex={control.zIndex}
            state={state}
            setState={setState}
            name={control.name}
            label={`${translatePrefix}.${control.name}`}
            key={control.name}
            className={twoPerLine ? 'col-6' : ''}
          />
        )
      case 'multiselect':
        return (
          <MultiSelectInput
            errors={errors}
            CreateTitle={control.CreateTitle}
            isCreatable={control.isCreatable}
            state={state}
            setState={control.setState ?? setState}
            isDisabled={control.isDisabled}
            name={control.name}
            touched={touched}
            setTouched={setTouched}
            tooltip={control.tooltip}
            tooltipFlag={control.tooltipFlag}
            label={`${translatePrefix}.${control.name}`}
            options={control.options}
            key={control.name}
            className={control.width ? 'col-6' : twoPerLine ? 'col-12' : ''}
            style={control._style}
            zIndex={control.zIndex}
            zIndexNumber={control.zIndexNumber}
          />
        )
      case 'createInput':
        return (
          <CreateInput
            state={state}
            setState={setState}
            name={control.name}
            touched={touched}
            setTouched={setTouched}
            placeholder={control.placeholder}
            errors={errors}
            label={`${translatePrefix}.${control.name}`}
            options={control.options}
            key={control.name}
            className={control._className === 'col-6' ? 'col-6' : 'col-12'}
            isCreatable={true}
            onCreateOption={control._onCreateOption}
            tooltip={control.tooltip}
            tooltipFlag={control.tooltipFlag}
            CreateTitle={control.createTitle}
            textWarning={control._textWarning}
          />
        )
      case 'createableMultiselect':
        return (
          <MultiSelectInput
            state={state}
            setState={setState}
            name={control.name}
            touched={touched}
            setTouched={setTouched}
            placeholder={control.placeholder}
            errors={errors}
            label={`${translatePrefix}.${control.name}`}
            options={control.options}
            key={control.name}
            className={control._className === 'col-6' ? 'col-6' : 'col-12'}
            isCreatable={true}
            onCreateOption={control._onCreateOption}
            tooltip={control.tooltip}
            tooltipFlag={control.tooltipFlag}
            CreateTitle={control.createTitle}
            textWarning={control._textWarning}
          />
        )
      case 'checkbox':
        return (
          <CheckBoxInput
            state={control._state ?? state}
            setState={control._setState ?? setState}
            name={control.name}
            label={`${translatePrefix}.${control.name}`}
            key={control.name}
            className={control.flag ? 'col-6 checkbox-custom' : twoPerLine ? 'col-12' : ''}
          />
        )
      case 'checkbox-disabled':
        return (
          <CheckBoxInput
            state={state}
            setState={setState}
            name={control.name}
            label={`${translatePrefix}.${control.name}`}
            key={control.name}
            className={control.flag ? 'col-6 checkbox-custom' : twoPerLine ? 'col-12' : ''}
            isDisabled={true}
          />
        )
      case 'password':
        return (
          <PasswordInput
            state={state}
            setState={control.setState || setState}
            touched={touched}
            setTouched={setTouched}
            errors={errors}
            name={control.name}
            label={`${translatePrefix}.${control.name}`}
            key={control.name}
            isDisabled={control._disabled}
            className={twoPerLine ? 'col-6' : ''}
            mode={control.mode}
            placeholder={control.placeholder}
          />
        )
      case 'colors':
        return (
          <ColorPickerInput
            key={control.name}
            state={control._state ?? state}
            setState={control._setState ?? setState}
            touched={touched}
            setTouched={setTouched}
            errors={errors}
            name={control.name}
            label={`${translatePrefix}.${control.name}`}
            isDisabled={control.isDisabled}
            className={twoPerLine ? 'col-12' : ''}
            errorText={control.errMsg}
            style={control._style}
          />
        )
    }
  })
}

export function getConvertPoolName(gateway: GatewayEntity) {
  return function (from: string) {
    const { Pools, Lps } = gateway

    const pool = Pools.find((item: any) => item.Name === from)
    if (pool) {
      return buildPoolName(pool, Lps)
    }
    return from
  }
}

export function cutPoolType(pool: string) {
  return pool.slice(0, pool.length - 4)
}

export function buildPoolName(pool: any, Lps: any): string {
  const findLPType = Lps?.filter((item: any) => pool?.Lps?.includes(item.Name))

  if (pool.Type) {
    const poolName = `${pool.Name} (${pool.Type[0]})`

    if (pool?.Type === 'Mixed' || findLPType[0]?.Type === 'T4BHedged' || isSwitchUnsupportedPoolType(pool.AggregationType)) {
      return `${poolName} (N/A)`
    }

    return poolName
  }
  return pool.Name
}

export function buildPoolsInput(predefined: string[], gateway: GatewayEntity, except?: string): string[] {
  if (gateway && Array.isArray(gateway.Pools)) {
    if (except) {
      return [...predefined, ...gateway.Pools.filter((pool: any) => pool.Name !== except).map((pool: any) => pool.Name)]
    }
    return [...predefined, ...gateway.Pools.map((pool: any) => pool.Name)]
  }

  return [...predefined]
}

export function buildPoolsInputNotBbook(predefined: string[], gateway: GatewayEntity, except?: string): string[] {
  if (gateway && Array.isArray(gateway.Pools)) {
    if (except) {
      return [
        ...predefined,
        ...gateway.Pools.filter((pool: any) => pool.Name !== except)
          .filter((pool: any) => pool.Type !== 'B-book' && pool.Type !== 'Mixed')
          .map((pool: any) => pool.Name),
      ]
    }
    return [...predefined, ...gateway.Pools.filter((pool: any) => pool.Type !== 'B-book' && pool.Type !== 'Mixed').map((pool: any) => pool.Name)]
  }
  return [...predefined]
}

function getPoolType(pool: any) {
  if (pool) {
    if (pool.Type === 'B-book') {
      return 'B-book'
    }
    return 'A-book'
  }
  return ''
}

export function isNotProportionalPool(pool: any): boolean {
  return pool.AggregationType !== 'MultiProportionalBook' && pool.AggregationType !== 'ProportionalExceptClose'
}

export function buildPoolsInputSwitch(predefined: string[], gateway: GatewayEntity, except: string): string[] {
  if (gateway && Array.isArray(gateway.Pools)) {
    const currentPool = gateway.Pools.find((pool: any) => pool.Name === except)
    return [...predefined, ...gateway.Pools.filter((pool: any) => getPoolType(currentPool) !== getPoolType(pool)).map((pool: any) => pool.Name)]
  }
  return [...predefined]
}

export function isNotCoveragePlatform(platform: GatewayPlatformEntity): boolean {
  return platform.Type !== PlatformType.mtexec
}

export function isNotMt4Platform(platform: GatewayPlatformEntity): boolean {
  return platform.Type !== PlatformType.mt4
}

export function isNotFixPlatform(platform: GatewayPlatformEntity): boolean {
  return platform.Type !== PlatformType.fixapi
}

export function isNotExtApiPlatform(platform: GatewayPlatformEntity): boolean {
  return platform.Type !== PlatformType.extapi
}

export function isNotOZApiPlatform(platform: GatewayPlatformEntity): boolean {
  return platform.Type !== PlatformType.fixapioz
}

export function isNotPXMApiPlatform(platform: GatewayPlatformEntity): boolean {
  return platform.Type !== PlatformType.fixapipxm
}

export function isNotMatchTradeApiPlatform(platform: GatewayPlatformEntity): boolean {
  return platform.Type !== PlatformType.CTrader
}

export function isNotCTraderApiPlatform(platform: GatewayPlatformEntity): boolean {
  return platform.Type !== PlatformType.MatchTrade
}

export function firstPlatform(gateway: GatewayEntity, ...predicats: ((platform: GatewayPlatformEntity) => boolean)[]): GatewayPlatformEntity {
  if (Array.isArray(gateway.Platforms)) {
    let platforms = gateway.Platforms
    for (const predicat of predicats) {
      if (typeof predicat === 'function') {
        platforms = platforms.filter(predicat)
      }
    }

    return platforms[0] ?? new GatewayPlatformEntity()
  }
  return new GatewayPlatformEntity()
}

export function firstPlatformNotCoverage(gateway: GatewayEntity): GatewayPlatformEntity {
  return firstPlatform(gateway, isNotCoveragePlatform)
}

export function buildPlatformsInput(predefined: string[], gateway: GatewayEntity): string[] {
  if (gateway && Array.isArray(gateway.Platforms)) {
    return [...predefined, ...gateway.Platforms.filter(item => item.Type !== PlatformType.mtexec).map(item => item.Name)]
  }
  return [...predefined]
}

export const selectStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: '#30527A',
    border: 'none',
    boxShadow: 'none',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: 'white',
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    color: 'white',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: 'white',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    position: 'static',
  }),
  input: (provided: any) => ({
    ...provided,
    color: 'white',
  }),
}

export type Converter<T = string, U = string> = (key: T) => U

export function allConverter(translate: Converter): Converter {
  return function (option: string) {
    if (option === 'All') {
      return translate('All')
    }
    if (option === 'Mixed') {
      return translate('Mixed')
    }
    if (option === 'None') {
      return translate('None')
    }
    return option
  }
}

export function translateAllOptionDecorator(translate: Converter, converter: Converter): Converter {
  return function (option: string) {
    if (option === 'All') {
      return translate('All')
    }
    return converter(option)
  }
}

export function roundTypesConverter(translate: Converter): Converter {
  return function (option: string) {
    return translate(`round-type.${option}`)
  }
}

export function executionPriceSourceConverter(translate: Converter): Converter {
  return function (option: string) {
    return translate(`price-source.${option}`)
  }
}

export function commissionTypesConverter(translate: Converter): Converter {
  return function (option: string) {
    return translate(`commission-type.${option}`)
  }
}

export function translator(translate: Converter, translatePrefix: string): Converter {
  return function (option: string) {
    return translate(`${translatePrefix}.${option}`)
  }
}
