import { buildMultiselectOptionsFromArray, buildSelectOption } from '../utils/multiselect-utils'
import { IMultiSelectItem } from './multiselect'
import { LpSession } from './platforms'
import { UserRoles } from '../redux/actions/authentication'
import * as yup from 'yup'
import { expToNum } from '../utils/num-utils'

interface IPool {
  Name: any
  Type: string
}

export class GatewayPlatformEntity {
  public Name: string
  public Type: string
  public Symbols: any
  public Sessions: string[]
  public Tags: string[]
  public StatementsSendingEnabled: boolean

  constructor(item?: any) {
    if (item) {
      this.StatementsSendingEnabled = item.StatementsSendingEnabled
      this.Tags = item.Tags
      this.Name = item.Name
      this.Type = item.Type
      this.Symbols = buildMultiselectOptionsFromArray(item.Symbols)
      this.Sessions = item.Sessions ? item.Sessions : []
    } else {
      this.StatementsSendingEnabled = false
      this.Tags = []
      this.Name = ''
      this.Type = ''
      this.Symbols = []
      this.Sessions = []
    }
  }
}

export class GatewayEntity {
  Name: string
  Platforms: GatewayPlatformEntity[]
  Pools: IPool[]
  Lps: IMultiSelectItem[]
  Processors: any[]
  ExposureCurrencies: IMultiSelectItem[]
  EnablePriceOption: boolean
  SslProtocols: string[]
  FeederInvalidationTimeout: number
  StatConnectionStringFilled: boolean;

  [key: string]: any

  constructor(item?: GatewayEntity) {
    if (item) {
      this.StatConnectionStringFilled = item.StatConnectionStringFilled
      this.FeederInvalidationTimeout = item.FeederInvalidationTimeout
      this.Name = item.Name
      this.Platforms = item.Platforms.map((platform: any) => new GatewayPlatformEntity(platform))
      this.Pools = item.Pools
      this.Lps = item.Lps ? buildMultiselectOptionsFromArray(item.Lps) : []
      this.Processors = item.Processors
      this.ExposureCurrencies = item.ExposureCurrencies ? buildMultiselectOptionsFromArray(item.ExposureCurrencies) : []
      this.EnablePriceOption = item.EnablePriceOption ?? true
      this.SslProtocols = item.SslProtocols
    } else {
      this.StatConnectionStringFilled = false
      this.FeederInvalidationTimeout = 0
      this.SslProtocols = []
      this.Name = ''
      this.Platforms = []
      this.Pools = []
      this.Lps = []
      this.Processors = []
      this.ExposureCurrencies = []
      this.EnablePriceOption = false
    }
  }
}

export class GatewaySettingsEntity {
  Name: string
  ServerApiAddress: string
  ClientApiAddress: string
  RestoreMaxPerSec: number
  LimitsCheckTimeout: number
  ImmediateOrdersCheckTimeout: number
  ExposureCurrencies: IMultiSelectItem[];

  [key: string]: any

  static schema = {
    Name: yup.string().required(),
    ServerApiAddress: yup.string().required(),
    ClientApiAddress: yup.string().required(),
    RestoreMaxPerSec: yup.number().positive().integer().required(),
    LimitsCheckTimeout: yup.number().positive().integer().required(),
    ImmediateOrdersCheckTimeout: yup.number().positive().integer().required(),
    ExposureCurrencies: yup.array().required(),
  }

  constructor(item?: any) {
    if (item) {
      this.Name = item.Name
      this.ServerApiAddress = item.ServerApiAddress
      this.ClientApiAddress = item.ClientApiAddress
      this.RestoreMaxPerSec = item.RestoreMaxPerSec
      this.LimitsCheckTimeout = item.LimitsCheckTimeout
      this.ImmediateOrdersCheckTimeout = item.ImmediateOrdersCheckTimeout
      this.ExposureCurrencies = item.ExposureCurrencies ? buildMultiselectOptionsFromArray(item.ExposureCurrencies) : []
    } else {
      this.Name = ''
      this.ServerApiAddress = ''
      this.ClientApiAddress = ''
      this.RestoreMaxPerSec = 0
      this.LimitsCheckTimeout = 0
      this.ImmediateOrdersCheckTimeout = 0
      this.ExposureCurrencies = []
    }
  }
}

export class SmtpEntity {
  SmtpServerHost: string
  SmtpServerPort: number
  UseSsl: boolean
  SendOnRestart: boolean;

  [key: string]: any

  static schema = {
    SmtpServerHost: yup.string().required(),
    SmtpServerPort: yup.number().required(),
    UseSsl: yup.boolean().required(),
    SendOnRestart: yup.boolean().required(),
  }

  constructor(item?: any) {
    if (item) {
      this.SmtpServerHost = item.SmtpServerHost
      this.SmtpServerPort = item.SmtpServerPort
      this.UseSsl = item.UseSsl
      this.SendOnRestart = item.SendOnRestart
    } else {
      this.SmtpServerHost = 'smtp.gmail.com'
      this.SmtpServerPort = 587
      this.UseSsl = true
      this.SendOnRestart = false
    }
  }
}

export class LpSymbol {
  public Symbol: string
  public LpSymbol: string
  public ContractMultiplier: string
  public DealCurrency: string
  public Type: string
  public VolumeStep: any
  public error: number
  public Exchange: string
  public LastUpdateTime: string | number
  public LastTickValue: string

  constructor(item?: any) {
    if (item) {
      this.LastTickValue = item.LastTickValue
      this.LastUpdateTime = item.LastUpdateTime
      this.VolumeStep = expToNum(item?.VolumeStep)
      this.Symbol = item.Symbol
      this.LpSymbol = item.LpSymbol
      this.ContractMultiplier = expToNum(item.ContractMultiplier)
      this.DealCurrency = item.DealCurrency
      this.Type = item.Type
      this.error = item.error
      this.Exchange = item.Exchange
    } else {
      this.LastTickValue = 'N/A'
      this.LastUpdateTime = 0
      this.Exchange = ''
      this.VolumeStep = null
      this.Symbol = ''
      this.LpSymbol = ''
      this.ContractMultiplier = ''
      this.DealCurrency = ''
      this.Type = ''
      this.error = 0
    }
  }
}

export class LpEntity {
  Name: string
  Type: any
  Server: any
  Login: any
  Password: any
  FeedingSession: LpSession
  ExecutionSession: LpSession
  CryptoFutures: any

  constructor(item?: any) {
    if (item) {
      this.Name = item.Name
      this.Type = item.Type
      this.Server = item.Server
      this.Login = item.Login
      this.Password = item.Password
      this.FeedingSession = new LpSession(item.FeedingSession)
      this.ExecutionSession = new LpSession(item.ExecutionSession)
      this.CryptoFutures = new LMAXCryptoClass(item.CryptoFutures)
    } else {
      this.Name = ''
      this.Type = 'LMAXLD'
      this.FeedingSession = new LpSession()
      this.ExecutionSession = new LpSession()
      this.CryptoFutures = new LMAXCryptoClass()
    }
  }
}

export class LpAlpaca {
  Name: string
  Type: string
  ApiKey: string
  ApiSecret: string
  Live: boolean

  constructor(item?: any) {
    this.Name = item?.Name ?? ''
    this.Type = item?.Type ?? 'AlpacaMarkets'
    this.ApiKey = item?.ApiKey ?? ''
    this.ApiSecret = item?.ApiSecret ?? ''
    this.Live = item?.Live ?? false
  }
}

export class InteractiveBrokers {
  Name: string
  LogPath: string
  ReconnectTimeout: string | number
  DepthLevels: IMultiSelectItem
  EnableFeederLogging: boolean
  WebServiceUrl: string
  WebServicePort: string | number
  Type: string
  constructor(item?: any) {
    this.Name = item?.Name ?? ''
    this.Type = item?.Type ?? ''
    this.LogPath = item?.LogPath ?? ''
    this.ReconnectTimeout = item?.ReconnectTimeout ?? 10000
    this.DepthLevels = item?.DepthLevels ?? '10'
    this.EnableFeederLogging = item?.EnableFeederLogging ?? false
    this.WebServiceUrl = item?.WebServiceUrl ?? '127.0.0.1'
    this.WebServicePort = item?.WebServicePort ?? '7496'
  }
}

export class Binance {
  Name: string
  Type: string
  ApiKey: string
  ApiSecret: any
  LogPath: string
  AllowedCancellationsForUser: any
  AllowedCancellationsForSession: any
  CancellationRatingResetTimeout: number
  ReconnectTimeout: number
  TasksLimit: number
  DepthLevels: IMultiSelectItem
  QuotesUpdateInterval: IMultiSelectItem
  StreamLastPrices: boolean
  EnableFeederLogging: boolean
  UseDefaultServers: boolean
  Leverage: number
  WebServiceUrl?: string
  WebServicePort?: string
  ConfirmClosingOrdersUnderMinNotional: boolean
  ReceiveWindow: number
  Live?: boolean
  PassPhrase?: string
  RestAddress?: string
  SocketStreamAddress?: string
  SocketApiAddress?: string

  constructor(item?: any) {
    this.RestAddress = item.RestAddress ?? ''
    this.SocketStreamAddress = item.SocketStreamAddress ?? ''
    this.SocketApiAddress = item.SocketApiAddress ?? ''
    this.UseDefaultServers = item.UseDefaultServers ?? true
    this.ConfirmClosingOrdersUnderMinNotional = item.ConfirmClosingOrdersUnderMinNotional ?? false
    this.ReceiveWindow = item.ReceiveWindow ?? 10000
    this.Name = item?.Name ?? ''
    this.Type = item?.Type ?? ''
    this.ApiKey = item?.ApiKey ?? ''
    this.ApiSecret = item?.ApiSecret ?? ''
    this.LogPath = item?.LogPath ?? ''
    this.AllowedCancellationsForSession = item?.AllowedCancellationsForSession ?? 25
    this.AllowedCancellationsForUser = item?.AllowedCancellationsForUser ?? 5
    this.CancellationRatingResetTimeout = item?.CancellationRatingResetTimeout ?? 10000
    this.ReconnectTimeout = item?.ReconnectTimeout ?? 60000
    this.TasksLimit = item?.TasksLimit ?? 100
    this.DepthLevels = item?.DepthLevels // 10
    this.QuotesUpdateInterval = item?.QuotesUpdateInterval // 100
    this.StreamLastPrices = item?.StreamLastPrices ?? true
    this.EnableFeederLogging = item.EnableFeederLogging ?? false
    this.Leverage = item.Leverage ?? 1
    this.WebServiceUrl = item?.WebServiceUrl ?? ''
    this.WebServicePort = item.WebServicePort ?? ''
    this.Live = item.Live ?? false
    this.PassPhrase = item.PassPhrase ?? ''
  }
}

export class isHedging {
  Mask: string
  Threshold: string

  constructor(item?: any) {
    this.Mask = item?.Mask ?? ''
    this.Threshold = item?.Threshold ?? ''
  }
}

export class HedgingBBook {
  Name: string
  Type: string
  Thresholds: any
  AggregationPool: any

  constructor(item?: any) {
    this.AggregationPool = item?.AggregationPool ?? ''
    this.Name = item?.Name ?? ''
    this.Type = item?.Type ?? 'BbookHedged'
    this.Thresholds = item?.Thresholds ?? []
  }
}

export class Laverate {
  ApiKey: string
  ApiSecret: string
  LogPath: string
  ReconnectTimeout: number
  EnableFeederLogging: boolean
  WebServiceUrl?: string
  WebServicePort?: string

  constructor(item?: any) {
    this.WebServicePort = item.WebServicePort ?? ''
    this.ApiKey = item?.ApiKey ?? ''
    this.ApiSecret = item?.ApiSecret ?? ''
    this.LogPath = item?.LogPath ?? ''
    this.ReconnectTimeout = item?.ReconnectTimeout ?? 100000
    this.EnableFeederLogging = item?.EnableFeederLogging ?? false
    this.WebServiceUrl = item?.WebServiceUrl ?? ''
  }
}
// ==================================================
export class LMAXCryptoCredentialsClass {
  Login: number
  ServerIP: string
  ServerName: string
  Password: string
  ServerOwner: string
  Comment: string

  constructor(item?: any) {
    this.Login = item?.Login ?? ''
    this.ServerIP = item?.ServerIP ?? ''
    this.ServerName = item?.ServerName ?? ''
    this.Password = item?.Password ?? ''
    this.ServerOwner = item?.ServerOwner ?? ''
    this.Comment = item?.Comment ?? ''
  }
}

export class LMAXCryptoConnectorsClass {
  ConnectorType: []
  ConnectorName: string
  GroupMask: string
  SymbolMask: string
  Credentials: any
  Id: number
  disabled: boolean

  constructor(item?: any) {
    this.Id = item?.Id ?? Math.round(1 - 0.5 + Math.random() * (9999 - 1 + 1))
    this.disabled = item?.disabled ?? true
    this.ConnectorType = item?.ConnectorType ?? ''
    this.ConnectorName = item?.ConnectorName ?? ''
    this.GroupMask = item?.GroupMask ?? '*'
    this.SymbolMask = item?.SymbolMask ?? '*'
    this.Credentials = item?.Credentials ?? new LMAXCryptoCredentialsClass()
  }
}

export class LMAXCryptoClass {
  CheckInterval: number
  PriceDocumentLink: string
  Enabled: boolean
  ConnectorParameters: object

  constructor(item?: any) {
    this.CheckInterval = item?.CheckInterval ?? 24 * 60 * 60 * 1000
    this.PriceDocumentLink = item?.PriceDocumentLink ?? ''
    this.Enabled = item?.Enabled ?? false
    this.ConnectorParameters = item?.ConnectorParameters ?? {
      Connectors: [new LMAXCryptoConnectorsClass()].map((item: any) => {
        if (item.disabled === true) {
          item.disabled = false
        }
        return item
      }),
    }
  }
}
// ==================================================

export class LpVolumeTransformer {
  AggregationPool?: any

  constructor(item?: any) {
    this.AggregationPool = item?.AggregationPool ?? ''
  }
}

export class GlobalSymbol {
  Id: number
  Symbol: string
  LpSymbols: any[]
  PlatformSymbols: any[]
  Description: string
  Aliases: any[]
  Digits: number
  State: string
  Type: string

  constructor(item?: any) {
    if (item) {
      this.Type = item.Type
      this.State = item.State
      this.Digits = item.Digits
      this.Aliases = item.Aliases
      this.Id = item.Id
      this.Symbol = item.Symbol
      this.LpSymbols = item.LpSymbols
      this.PlatformSymbols = item.PlatformSymbols
      this.Description = item.Description
    } else {
      this.Type = ''
      this.State = ''
      this.Aliases = []
      this.Digits = 5
      this.Id = -1
      this.Symbol = ''
      this.LpSymbols = []
      this.PlatformSymbols = []
      this.Description = ''
    }
  }
}

export class UserEntity {
  Id: number
  Username: string
  Password: string
  Roles: UserRoles[]
  Description: string
  AllowedAddress: IMultiSelectItem[]
  UserType: any

  parseAllowedAddress(address: any): IMultiSelectItem[] {
    if (Array.isArray(address)) {
      return [...address]
    }

    if (typeof address === 'string') {
      const addressArray = address.split(';')
      const newAddress = addressArray.map((el: any) => buildSelectOption(el))
      return newAddress
    }

    return [buildSelectOption('*')]
  }

  constructor(item?: any) {
    if (item) {
      this.UserType = item.UserType
      this.Id = item.Id
      this.Username = item.Username
      this.Password = item.Password
      this.Roles = item.Roles
      this.Description = item.Description
      this.AllowedAddress = this.parseAllowedAddress(item.AllowedAddress)
    } else {
      this.UserType = 'custom'
      this.Id = 0
      this.Username = ''
      this.Password = ''
      this.Roles = []
      this.Description = ''
      this.AllowedAddress = [{ label: '*', value: '*' }]
    }
  }
}
