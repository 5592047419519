import { faCheckSquare, faCog, faSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Button, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'

interface IConfTableProps {
  confItems: any[]
  translatePrefix: string
  className?: string

  setConfItems(arg: any): any
}

export function withTooltipPreventOverflow(item: any, text: string, key: any, size?: any, placement: any = 'top') {
  const overlay = (
    <Tooltip id={`tooltip-${key}`}>
      <FormattedMessage id={text} />
    </Tooltip>
  )

  return (
    <OverlayTrigger key={key} overlay={overlay} placement={placement}>
      {item}
    </OverlayTrigger>
  )
}

const ConfTable: React.FC<IConfTableProps> = ({ confItems, setConfItems, translatePrefix, className }) => {
  const [checkboxState, setCheckboxState] = useState<any>([])

  useEffect(() => {
    let savedState: any = localStorage.getItem(translatePrefix)
    if (savedState) {
      savedState = JSON.parse(savedState)
      if (Array.isArray(savedState)) {
        savedState = savedState.map((item: any) => {
          const itemWithFunctions = confItems.find((elem: any) => elem.name === item.name) || {}
          return { ...itemWithFunctions, ...item }
        })
      } else {
        savedState = []
      }
    }
    const initialState = savedState ? savedState : confItems
    setCheckboxState(initialState.map((item: any) => ({ ...item })))
    setConfItems(initialState.map((item: any) => ({ ...item })))
  }, [confItems]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelect = () => {
    setShow(!show)
    localStorage.setItem(translatePrefix, JSON.stringify(checkboxState))
    setConfItems(checkboxState.map((item: any) => ({ ...item })))
  }

  function handleRestoreDefaults() {
    setShow(!show)
    setCheckboxState(confItems.map((item: any) => ({ ...item })))
    setConfItems(confItems.map((item: any) => ({ ...item })))
    localStorage.removeItem(translatePrefix)
  }

  const setCheckbox = (index: number) => {
    const newState = [...checkboxState]
    newState[index].show = !newState[index].show
    setCheckboxState(newState)
  }

  function fillValues() {
    return checkboxState
      .map((item: any, index: any) => {
        const wrapper = () => setCheckbox(index)
        return item.configurable === false ? null : (
          <Dropdown.Item onSelect={wrapper} key={item.name}>
            <FontAwesomeIcon icon={item.show ? faCheckSquare : faSquare} className="mr-2" style={{ color: '#007bff' }} />
            <FormattedMessage id={`${translatePrefix}.${item.name}`} />
          </Dropdown.Item>
        )
      })
      .filter((item: any) => item)
  }

  const [show, setShow] = React.useState(false)

  function handleToogle(isOpen: boolean, event: any, metadata: { source: 'select' | 'click' | 'rootClose' | 'keydown' }) {
    if (metadata.source === 'select') {
      return
    }
    setShow(!show)
  }

  const checkboxItems = fillValues()

  return (
    <Dropdown drop="left" show={show} onToggle={handleToogle} className={classNames('conf-table cursor-pointer', className)}>
      <Dropdown.Toggle variant="success" id="dropdown-basic" as="span">
        {withTooltipPreventOverflow(
          <div style={{ width: '28px', height: '28px', borderRadius: '4px' }} className="d-flex justify-content-center align-items-center btn-conf">
            <FontAwesomeIcon icon={faCog} />
          </div>,
          'column-visibility',
          'column-visibility',
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <div className="d-flex">
          <div>{checkboxItems.slice(0, checkboxItems.length / 2)}</div>
          <div>{checkboxItems.slice(checkboxItems.length / 2, checkboxItems.length)}</div>
        </div>
        <Dropdown.Item onSelect={handleRestoreDefaults}>
          <Button variant="primary" size="sm" block={true}>
            <FormattedMessage id="restore" />
          </Button>
        </Dropdown.Item>
        <Dropdown.Item onSelect={handleSelect}>
          <Button variant="primary" size="sm" block={true}>
            <FormattedMessage id="apply" />
          </Button>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default React.memo(ConfTable)
