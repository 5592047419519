import React, { memo, SyntheticEvent, useEffect, useLayoutEffect } from 'react'
import TextInput from '../../inputs/TextInput'
import AppButton from '../../AppButton'
import { useFormValidation } from '../../../hooks/useFormValidation'
import * as yup from 'yup'
import { buildMultiselectOptionsFromArray } from '../../../utils/multiselect-utils'
import { useQuery } from '../../../hooks/useQuery'
import { useDispatch, useSelector } from 'react-redux'
import MultiSelectInput from '../../inputs/MultiSelectInput'
import { ILeverageRules, updateLeverageProfile } from '../../../redux/reducers/leverageProfileReducer'
import { RootState } from '../../../redux/reducers/rootReducer'

export const LeverageProfileTableIRuleRightbar = memo(({ item, Id, handleStateChange, setValidTable, handleClone, handleDelete, handleActive }: any) => {
  const dispatch = useDispatch()
  const query = useQuery()
  const { gateway } = useSelector((reduxState: RootState) => reduxState.gateways)
  const [inputState, setInputState, touched, setTouched, errors, isValid] = useFormValidation(item, {
    Name: yup.string().required(),
    Symbols: yup
      .array()
      .of(
        yup.object().shape({
          value: yup.string().required(),
          label: yup.string().required(),
        }),
      )
      .required(),
    Leverage: yup
      .string()
      .matches(/^[0-9]+$/gi)
      .max(4)
      .test('test', 'test', value => value > 0),
  })

  useLayoutEffect(() => {
    setInputState(item)
  }, [item])

  useEffect(() => {
    const valid = isValid()
    setValidTable((prev: Record<number, boolean>) => {
      return {
        ...prev,
        [item.Id]: valid,
      }
    })
  }, [inputState])

  const platformName = query.get('platform')
  const activePlatform = gateway?.Platforms?.filter((item: any) => platformName === item.Name)
  const platformTags = activePlatform.map((item: any) => item.Tags).flat(Infinity)
  const platformSymbols = activePlatform
    .map((item: any) => item.Symbols)
    .flat(Infinity)
    .map((item: any) => item.value)

  const options = [
    {
      label: '---Symbols---',
      value: '1',
      options: buildMultiselectOptionsFromArray(platformSymbols.filter((item: string, i: number, arr: string[]) => arr.indexOf(item) === i)),
    },
    {
      label: '---Tags---',
      value: '2',
      options: buildMultiselectOptionsFromArray(platformTags.filter((item: string, i: number, arr: string[]) => arr.indexOf(item) === i)),
    },
  ]

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(
        updateLeverageProfile({
          Rules: inputState,
          Id: Id,
        }),
      )
    }, 300)

    return () => {
      clearTimeout(timeout)
    }
  }, [inputState])

  return (
    <>
      <td>
        <TextInput
          className={'m-0'}
          state={inputState}
          setState={(event: ILeverageRules) => {
            handleStateChange(event)
            setInputState(event)
          }}
          name="Name"
          setTouched={setTouched}
          touched={touched}
          errors={errors}
          isDisabled={inputState.Name === 'Default' ? true : !inputState.IsActive}
        />
      </td>
      <td className="textStart">
        <MultiSelectInput
          state={inputState}
          setState={(event: ILeverageRules) => {
            handleStateChange(event)
            setInputState(event)
          }}
          isCreatable
          CreateTitle={'Add mask:'}
          name="Symbols"
          options={options}
          setTouched={setTouched}
          touched={touched}
          errors={errors}
          isDisabled={inputState.Name === 'Default' ? true : !inputState.IsActive}
        />
      </td>
      <td>
        <TextInput
          className={'m-0'}
          state={inputState}
          setState={(event: ILeverageRules) => {
            handleStateChange(event)
            setInputState(event)
          }}
          name="Leverage"
          setTouched={setTouched}
          touched={touched}
          errors={errors}
          isDisabled={!inputState.IsActive}
        />
      </td>
      <td
        onClick={(event: SyntheticEvent) => {
          event.stopPropagation()
        }}
        className="d-flex justify-content-center"
      >
        <AppButton
          variant="disabled"
          onClick={inputState.Name === 'Default' ? () => {} : handleActive}
          className={inputState.Name === 'Default' ? `mb-2 mr-2 opacity-65` : `mb-2 mr-2`}
        />
        <AppButton variant="clone" onClick={handleClone} className="mb-2 mr-2" />
        <AppButton
          variant="delete"
          onClick={inputState.Name === 'Default' ? () => {} : handleDelete}
          className={inputState.Name === 'Default' ? `mb-2 mr-2 opacity-65` : `mb-2 mr-2`}
        />
      </td>
    </>
  )
})
